import React, { useState } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import Image from 'next/image';
import { utils } from '@makeship/core';

import Modal from '..';
import Subscribe from '../../Subscribe';
import { H5, P2, Caption } from '../../Typography';

import config from '../../../../config.json';
import { modalTheme } from '../../../styles/themes';
import { getProductFromContext } from '../../../context/product';

const { routes } = config;

const ModalContent = styled.div`
  width: 100%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  position: relative;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 16px;
    margin-bottom: 30px;
  }
`;

const CancelIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`;

const ModalHeader = styled.div`
  text-align: center;
  margin-top: 16px;

  & > * + * {
    margin-top: 16px;
  }
`;

const SubscribeWrapper = styled.div`
  width: 100%;
  margin: 24px auto;
`;

const ModalCaption = styled(Caption)`
  text-align: center;
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
`;

const CaptionLink = styled.span`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  text-decoration: underline;
  cursor: pointer;
`;

type SubscribeModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  tags?: string;
  description?: string;
  title?: string;
  ctaText?: string;
  product?: Shopify.Product;
};

const SubscribeModal: React.FC<SubscribeModalProps> = ({
  isOpen,
  closeModal,
  tags,
  description,
  title,
  ctaText,
  product,
}: SubscribeModalProps) => {
  const [isSubscribed, setIsSubscribed] = useState(false);

  const subscribedProduct = product || getProductFromContext();

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} theme={modalTheme}>
      <ModalContent>
        <CancelIcon onClick={closeModal}>
          <Image src="/assets/icons/cancel-icon.svg" width="16" height="16" quality={90} />
        </CancelIcon>
        <ModalHeader>
          {isSubscribed ? (
            <H5 data-testid="subscribe-modal-title">Thank You For Subscribing!</H5>
          ) : (
            <H5 data-testid="subscribe-modal-title">{title || 'Be the first to know!'}</H5>
          )}
          {isSubscribed ? (
            <P2>
              {subscribedProduct && subscribedProduct.title
                ? `You will be notified when the next batch of the ${subscribedProduct.title} launches 🚀`
                : `Thank you for subscribing!`}
            </P2>
          ) : (
            <P2>
              {description ||
                'Get on our mailing list to connect with our Community. Every week you will be the first to know about events with Creators and new Campaigns.'}
            </P2>
          )}
        </ModalHeader>
        <SubscribeWrapper>
          {!isSubscribed && (
            <Subscribe
              label="Email"
              expanded
              ctaText={ctaText ? `${ctaText}` : 'Subscribe'}
              tags={tags || 'subscribed-footer'}
              setIsSubscribed={setIsSubscribed}
              product={subscribedProduct}
            />
          )}
        </SubscribeWrapper>
        <ModalCaption>
          By subscribing, you agree to our{' '}
          <Link href={routes.privacyPolicy}>
            <CaptionLink onClick={closeModal}>Privacy Policy</CaptionLink>
          </Link>
          . You can unsubscribe from our mailing list anytime.
        </ModalCaption>
      </ModalContent>
    </Modal>
  );
};

export default SubscribeModal;

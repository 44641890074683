import { gql } from '@apollo/client';
import {
  PRODUCT_DETAIL_FRAGMENT,
  PRODUCT_FRAGMENT_REDUCED,
  COLLECTION_FRAGMENT,
  COLLECTION_METAFIELD_IDENTIFIERS,
} from '../fragments';

export const GET_COLLECTION_BY_HANDLE = gql`
  ${PRODUCT_FRAGMENT_REDUCED}
  query GetCollectionByHandle($handle: String!, $first: Int!, $cursor: String, $images: Int) {
    collection(handle: $handle) {
      id
      title
      description
      descriptionHtml
      handle
      image {
        altText
        transformedSrc(maxWidth: 1000, preferredContentType: WEBP)
      }
      metafields(identifiers: ${COLLECTION_METAFIELD_IDENTIFIERS}) {
        key
        value
        namespace
        reference {
          ...on MediaImage {
            image {
              url
            }
          }
        }
        references(first: 10) {
          edges {
            node {
            __typename
              ... on MediaImage {
                image {
                  url
                }
              }
              ... on Collection {
                id
                title
                handle
              }
            }
          }
        }
      }
      products(first: $first, after: $cursor) {
        edges {
          node {
            ...ProductInfoReduced
          }
          cursor
        }
      }
    }
  }
`;

export const GET_COLLECTION_BY_ID = gql`
  ${PRODUCT_FRAGMENT_REDUCED}
  query GetCollectionByHandle($id: ID!, $first: Int!, $cursor: String, $images: Int) {
    collection(id: $id) {
      id
      title
      description
      descriptionHtml
      handle
      image {
        altText
        transformedSrc(maxWidth: 1000, preferredContentType: WEBP)
      }
      metafields(identifiers: ${COLLECTION_METAFIELD_IDENTIFIERS}) {
        key
        value
        namespace
        reference {
          ...on MediaImage {
            image {
              url
            }
          }
        }
        references(first: 10) {
          edges {
            node {
              ... on MediaImage {
                image {
                  url
                }
              }
            }
          }
        }
      }
      products(first: $first, after: $cursor) {
        edges {
          node {
            ...ProductInfoReduced
          }
          cursor
        }
      }
    }
  }
`;

export const GET_CHECKOUT_COLLECTION_BY_HANDLE = gql`
  ${PRODUCT_FRAGMENT_REDUCED}
  query GetCollectionByHandle($handle: String!, $first: Int!, $cursor: String, $images: Int) {
    collection(handle: $handle) {
      handle
      image {
        altText
        transformedSrc(maxWidth: 100, preferredContentType: WEBP)
      }
      products(first: $first, after: $cursor) {
        edges {
          node {
            ...ProductInfoReduced
          }
          cursor
        }
      }
    }
  }
`;

export const GET_COLLECTION_BY_HANDLE_DETAILED = gql`
  ${PRODUCT_DETAIL_FRAGMENT}
  query GetCollectionByHandle($handle: String!, $first: Int!, $cursor: String, $maxWidth: Int!) {
    collection(handle: $handle) {
      title
      description
      descriptionHtml
      handle
      image {
        altText
        transformedSrc(maxWidth: $maxWidth, preferredContentType: WEBP)
      }
      products(first: $first, after: $cursor) {
        edges {
          node {
            ...ProductDetails
          }
          cursor
        }
      }
    }
  }
`;

export const GET_COLLECTION_BY_QUERY = gql`
  ${COLLECTION_FRAGMENT}
  query GetCollectionsByQuery(
    $first: Int!
    $query: String!
    $sortKey: CollectionSortKeys
    $reverse: Boolean
    $images: Int
    $namespace: String!
    $key: String!
  ) {
    collections(first: $first, query: $query, sortKey: $sortKey, reverse: $reverse) {
      edges {
        node {
          ...CollectionInfo
        }
        cursor
      }
    }
  }
`;

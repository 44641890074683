import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Reaptcha from 'reaptcha';
import { Button } from '@makeship/core';
import StyledLink from '@makeship/core/lib/components/Link';
import posthog from 'posthog-js';
import Cookies from 'js-cookie';
import { subscribe } from '../../api/internal';
import { Overline, P2 } from '../Typography';
import { getProductIDFromShopifyGID, getStage } from '../../utils/product';
import { getCampaignType, getCreator, isUserLoggedIn } from '../../utils/analytics';
import { useStore } from '../../store';
import { CookieConsent } from '../../types/cookies';

const SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || '';

const InputForm = styled.form<{ expanded: boolean }>`
  display: flex;
  flex-direction: ${({ expanded }) => (expanded ? 'column' : 'row')};
  width: 100%;
  align-items: center;
  padding-top: 8px;
  ${({ expanded }) =>
    expanded &&
    `
  > * + * {
    width: 100%;
    margin-bottom: 8px;
  }`}
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;

    > * + * {
      width: 100%;
      margin-bottom: 8px;
    }
  }
`;

const Input = styled.input<{ expanded: boolean }>`
  height: 44px;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 8px;
  margin-right: ${({ expanded }) => (expanded ? '0' : '8px')};
  border: 1px solid ${({ theme }) => theme.colors.neutral7};
  min-width: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-right: 0;
  }
`;

const Message = styled.div`
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LabelP2 = styled(P2)``;

const ErrorP2 = styled(P2)`
  color: ${({ theme }) => theme.colors.error};
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubscribeButton = styled(Button.Secondary)`
  display: flex !important;
  display: inline-block;
  align-self: flex-start;
  justify-self: flex-start;
  min-width: 160px;
`;

const CaptchaSubtext = styled(Overline)`
  color: ${({ theme }) => theme.colors.neutral4};
`;

const PrivacyPolicyLink = styled(StyledLink.Primary)``;

const TermsOfServiceLink = styled(StyledLink.Primary)``;

export type SubscribeProps = {
  label: string;
  expanded: boolean;
  ctaText: string;
  product?: Shopify.Product;
  tags?: string;
  setIsSubscribed?: React.Dispatch<React.SetStateAction<boolean>>;
  componentLocation?: string;
};

const errLabel = 'Could not subscribe! Check your email and try again.';

const Subscribe: React.FC<SubscribeProps> = ({
  label,
  expanded,
  ctaText,
  product,
  tags,
  setIsSubscribed,
  componentLocation,
}: SubscribeProps) => {
  const { state } = useStore();
  const [captcha, setCaptcha] = useState('');
  const subscribeEmail = Cookies.get('subscribe_email');
  const [email, setEmail] = useState(`${state.user?.customer?.email || subscribeEmail || ''}`);
  const [subscribed, setSubscribed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const recaptchaRef = useRef<Reaptcha>(null);

  const handlePosthogCapture = (eventName: string) => {
    if (componentLocation === 'communityPage') {
      posthog.capture(eventName, {
        event_location: componentLocation,
        cta_text: ctaText,
        is_logged_in: isUserLoggedIn(state.user),
        tags: tags?.split(',').map((value) => value.trim()) || [],
      });
    }
    if (ctaText === 'Bring it back!') {
      product &&
        posthog.capture(eventName, {
          productID: getProductIDFromShopifyGID(product.id || ''),
          event_location: 'product_thumbnail_subscribe',
          product_name: product.title,
          creator: getCreator(product),
          campaign_type: getCampaignType(product) || '',
          campaign_status: getStage(product?.tags),
          is_logged_in: isUserLoggedIn(state.user),
          cta_text: ctaText,
          tags: tags?.split(',').map((value) => value.trim()) || [],
        });
    } else {
      product &&
        posthog.capture(eventName, {
          productID: getProductIDFromShopifyGID(product.id || ''),
          event_location: 'pdp_subscribe',
          product_name: product.title,
          creator: getCreator(product),
          campaign_type: getCampaignType(product) || '',
          campaign_status: getStage(product?.tags),
          is_logged_in: isUserLoggedIn(state.user),
          cta_text: ctaText,
          tags: tags?.split(',').map((value) => value.trim()) || [],
        });
    }
  };

  useEffect(() => {
    if (!captcha) {
      return;
    }
    const cookieConsentAccepted = Cookies.get(CookieConsent.Functional) === 'true';
    if (cookieConsentAccepted) {
      Cookies.set('subscribe_email', email, { expires: 365 });
    }
    setIsLoading(true);
    handlePosthogCapture('subscribe_cta_click');
    subscribe(email, captcha, tags)
      .then(() => {
        handlePosthogCapture('user_subscribed');
        setSubscribed(true);
        setIsSubscribed?.(true);
      })
      .catch(() => {
        setError(true);
        setSubscribed(false);
        setIsSubscribed?.(false);
        recaptchaRef.current?.reset();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [captcha, tags]);

  const onSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    recaptchaRef.current?.execute();
  };

  if (subscribed) {
    return <Message data-gtm-name="success-message">Thank you for subscribing!</Message>;
  }

  return (
    <>
      {!error ? <LabelP2>{label}</LabelP2> : <ErrorP2>{errLabel}</ErrorP2>}
      <InputForm onSubmit={onSubscribe} expanded={expanded}>
        <Reaptcha ref={recaptchaRef} size="invisible" sitekey={SITE_KEY} onVerify={(res) => setCaptcha(res)} />
        <FormColumn>
          <Input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.currentTarget.value);
            }}
            placeholder="example@email.com"
            required
            expanded={expanded}
          />
          <CaptchaSubtext>
            This site is protected by reCAPTCHA and the Google{' '}
            <PrivacyPolicyLink href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </PrivacyPolicyLink>{' '}
            and{' '}
            <TermsOfServiceLink href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </TermsOfServiceLink>{' '}
            apply.
          </CaptchaSubtext>
        </FormColumn>
        <SubscribeButton disabled={isLoading} type="submit">
          {ctaText}
        </SubscribeButton>
      </InputForm>
    </>
  );
};

export default Subscribe;

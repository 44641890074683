import { AxiosResponse } from 'axios';
import { StorefrontClient } from '../../clients';

export const getProductSalesQuantityByExternalProductId = async (
  productId: string,
): Promise<AxiosResponse<ProductSalesQuantity>> =>
  StorefrontClient.get<ProductSalesQuantity>(`/products/${productId}/sales-quantity`).catch((err) =>
    Promise.reject(err.response.data.message),
  );

export const getProductSalesQuantityForGT = async (): Promise<AxiosResponse<ProductSalesQuantity>> =>
  StorefrontClient.get<ProductSalesQuantity>(`/products/gt/sales-quantity`).catch((err) =>
    Promise.reject(err.response.data.message),
  );

export const getProductDropSalesQuantity = async (
  externalProductIds: string,
): Promise<AxiosResponse<ProductSalesQuantity>> =>
  StorefrontClient.post<ProductSalesQuantity>('/products/sales-quantity', {
    externalProductIds,
  }).catch((err) => Promise.reject(err.response.data.message));

export const getSideBarItems = async (): Promise<AxiosResponse<SidebarItemsType[]>> =>
  StorefrontClient.get<SidebarItemsType[]>('/sidebar/items').catch((err) => Promise.reject(err.response.data.message));

export const getTargetURLFromPath = async (pathUrl: string): Promise<AxiosResponse<RedirectResponse>> =>
  StorefrontClient.get<RedirectResponse>('/redirect', {
    params: { pathUrl },
  }).catch((err) => Promise.reject(err.response.data.message));

import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

const cleanHostname = (hostname: string) => hostname.replace('www.', '');

export const anonIDKey = 'anonID';

export const fetchUserIdentifier = () => {
  const cookieAnonID = Cookies.get(anonIDKey);
  const localStorageAnonID = localStorage.getItem(anonIDKey);

  if (!cookieAnonID && !localStorageAnonID) {
    const anonID = uuidv4();

    localStorage.setItem(anonIDKey, anonID);
    Cookies.set(anonIDKey, anonID, {
      expires: 365,
      domain: `.${cleanHostname(window.location.hostname)}`,
      sameSite: 'lax',
    });
  }

  if (cookieAnonID && (!localStorageAnonID || localStorageAnonID !== cookieAnonID)) {
    localStorage.setItem(anonIDKey, cookieAnonID);
  }

  return Cookies.get(anonIDKey);
};

import { scroller } from 'react-scroll';
import { utils } from '@makeship/core';
import { getCampaignStageTag, getStage } from '../../../utils/product';

export const scrollToPetitionProcess = () => {
  scroller.scrollTo('petition-process', {
    duration: 800,
    smooth: 'easeInOutQuad',
    offset: -150,
  });
};

export const scrollToVariants = () => {
  scroller.scrollTo('variants', {
    duration: 800,
    smooth: 'easeInOutQuad',
    offset: -300,
  });
};

export const hasPetitionLaunched = (launchDate: string | undefined): boolean => {
  if (!launchDate) return true;
  const launchDateObj = new Date(launchDate);
  const currentDate = new Date();
  return currentDate >= launchDateObj;
};

export const hasPetitionEnded = (petitionEndDate: string | undefined): boolean => {
  if (!petitionEndDate) return true;
  const endDate = new Date(petitionEndDate);
  const currentDate = new Date();
  return currentDate >= endDate;
};

export const calculatePrototypeLaunchDate = (
  paymentDate: string | undefined,
  petitionEndDateStr: string | undefined,
): string => {
  if (!paymentDate || !petitionEndDateStr) {
    return `1-4 months${hasPetitionEnded(petitionEndDateStr) ? ' post-purchase' : ''}`;
  }

  const overrideLaunchDate = new Date(paymentDate);
  const estimatedLaunchDate = new Date(petitionEndDateStr);
  estimatedLaunchDate.setMonth(estimatedLaunchDate.getMonth() + 4);

  if (estimatedLaunchDate >= overrideLaunchDate) {
    return `1-4 months${hasPetitionEnded(petitionEndDateStr) ? ' post-purchase' : ''}`;
  }

  const monthDifference =
    overrideLaunchDate.getMonth() -
    estimatedLaunchDate.getMonth() +
    (overrideLaunchDate.getFullYear() - estimatedLaunchDate.getFullYear()) * 12;

  if (monthDifference >= 1 && monthDifference <= 4) {
    return `1-4 months${hasPetitionEnded(petitionEndDateStr) ? ' post-purchase' : ''}`;
  }
  return utils.getDate(paymentDate);
};

export const renderLaunchDate = (paymentDate: string | undefined, petitionEndDate: string | undefined): string =>
  `${calculatePrototypeLaunchDate(paymentDate, petitionEndDate)}`;

export const createTags = (product: Shopify.Product, totalInventory: number, moq: number): string | undefined => {
  const stage = getStage(product.tags);
  // We will almost always return this
  if (product.id) {
    return `${product.title}, ${getCampaignStageTag(stage, totalInventory, moq)}, subscribed-product`;
  }
  // We need this to handle empty tags
  return undefined;
};

export const subscribeLabel = 'Enter your email to receive updates.';
